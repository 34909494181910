<template>
  <v-card flat>
    <!-- Start: Basic info -->
    <div class="px-4 pt-4">
      <div class="text-h6 font-weight-bold">Thông tin cơ bản</div>
      <!-- Start: Name -->
      <div class="mt-5">
        <div class="mb-2 font-weight-bold">Tên folder</div>
        <tp-text-field
          v-model="customizeFolder.name"
          auto-grow
          placeholder="Hãy đặt tên folder thật ngắn gọn, dễ  hiểu"
          validate="required|max:50"
        ></tp-text-field>
      </div>
      <!-- End: Name -->
      <!-- Start: Des -->
      <div>
        <div class="mb-2 font-weight-bold">Mô tả</div>
        <tp-textarea
          v-model="customizeFolder.des"
          :validate="'required'"
          placeholder="Nhập mô tả"
          rows="2"
        ></tp-textarea>
      </div>
      <!-- End: Des -->
      <!-- Start: Cover -->
      <div>
        <div class="mb-2 d-flex align-center justify-space-between">
          <div class="font-weight-bold">Ảnh cover</div>
        </div>

        <div class="px-2 py-1 mb-2 rounded amber lighten-5 text-body-2">
          Kích thước ảnh khuyến nghị
          <span class="font-weight-bold">là 2400 x 640 px</span>
        </div>

        <tp-input-image
          :acceptedFileTypes="['image/*']"
          :maxFileSize="500 * 1024"
          :src.sync="customizeFolder.cover"
          allowFileSize
          allowFileTypeValidation
          class="flex-grow-1"
          forceType="jpeg"
          required
        ></tp-input-image>
      </div>
      <!-- End: Cover -->

      <!-- Start: Cover -->
      <div>
        <div class="mb-2 d-flex align-center justify-space-between">
          <div class="font-weight-bold">Ảnh thumbnail</div>
        </div>

        <div class="px-2 py-1 mb-2 rounded amber lighten-5 text-body-2">
          Kích thước ảnh khuyến nghị
          <span class="font-weight-bold">là 1200 x 675 px</span>
        </div>

        <tp-input-image
          :acceptedFileTypes="['image/*']"
          :maxFileSize="500 * 1024"
          :src.sync="customizeFolder.thumbnail"
          allowFileSize
          allowFileTypeValidation
          class="flex-grow-1"
          forceType="jpeg"
          required
        ></tp-input-image>
      </div>
      <!-- End: Cover -->

      <div class="mt-4">
        <form-seo-data
          :key="customizeFolder.id"
          v-model="customizeFolder.seo_data"
        ></form-seo-data>
      </div>

      <div class="mt-4">
        <form-site-model
          :customize-folder="customizeFolder"
          :model-id="customizeFolder.id"
          :model-type="LIST_MODEL_TYPES.FOLDER"
          :value="modelSiteConfigs"
          @input="$emit('update:modelSiteConfigs', $event)"
        ></form-site-model>
      </div>
    </div>
    <!-- End: Basic info -->

    <!-- Start: Url -->
    <div class="pa-4">
      <div class="mb-2 font-weight-bold">Đường dẫn</div>

      <tp-text-field
        :disabled="Boolean(customizeFolder.id)"
        :validate="'required'"
        :value="slug"
        hide-details="auto"
        placeholder="/ten-folder-fd"
        @input="customizeFolder.shared_url = $event"
      >
      </tp-text-field>
    </div>
    <!-- End: Url -->
  </v-card>
</template>

<script>
// import FormSiteModelAvailable from "@/core/components/form/FormSiteModelAvailable";
import { LIST_MODEL_SITES, LIST_MODEL_TYPES } from "@/core/constant";
import FormSiteModel from "./FormSiteModel.vue";
import { removeVietnameseTones, toKebabCase } from "@vt7/utils";
import FormSeoData from "@/core/components/form/FormSeoData/index.vue";

export default {
  components: { FormSeoData, FormSiteModel },

  props: {
    modelSiteConfigs: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      currentSeoSiteTab: "THINKPRO",
      LIST_MODEL_SITES,
      LIST_MODEL_TYPES,
      thinkproFrontStoreUrl: process.env.VUE_APP_THINKPRO_FRONT_STORE
    };
  },

  computed: {
    customizeFolder() {
      return this.$store.getters["CUSTOMIZE_FOLDER/customizeFolder"];
    },
    slug() {
      return this.customizeFolder.id
        ? `${this.customizeFolder.shared_url}`
        : toKebabCase(removeVietnameseTones(this.customizeFolder.name));
    },
    seoData() {
      return this.customizeFolder.seo_data.reduce(
        (obj, cur) => ({ ...obj, [cur.site]: cur }),
        {}
      );
    }
  },

  watch: {
    "customizeFolder.name"(value) {
      if (!this.customizeFolder.id) {
        this.customizeFolder.shared_url = toKebabCase(
          removeVietnameseTones(value)
        );
      }
    }
  }
};
</script>
